import { Link, FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';

import SocialLink from './SocialLink';

import styles from './Footer.module.scss';

const Footer: React.FC = () => (
  <footer className={styles.footer}>
    <div className={styles.container}>
      <div className={styles.column}>
        <Link to="/guidelines" className={styles.footerLink}>
          <FormattedMessage id="components.footer.guidelines" />
        </Link>
        <Link to="/legal/terms/" className={styles.footerLink}>
          <FormattedMessage id="components.footer.terms" />
        </Link>
        <Link to="/legal/privacy/" className={styles.footerLink}>
          <FormattedMessage id="components.footer.privacyPolicy" />
        </Link>
      </div>
      <div className={`${styles.column} ${styles.columnMobileMargin}`}>
        <SocialLink service="instagram" />
        <SocialLink service="twitter" />
        <SocialLink service="facebook" />
      </div>
      <div className={styles.column}>
        <SocialLink service="youtube" />
        <SocialLink service="linkedin" />
      </div>
    </div>
  </footer>
);

export default Footer;
