import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';

import InstallButton from 'components/InstallButton';

import appIcon from './app-icon.svg';

import styles from './DownloadAlbums.module.scss';

const DownloadAlbums: React.FC = () => {
  const {
    downloadAlbums1,
    downloadAlbums2,
  } = useStaticQuery(
    graphql`
        query {
            downloadAlbums1: file(relativePath: { eq: "download-albums-1.png" }) {
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            }
            downloadAlbums2: file(relativePath: { eq: "download-albums-2.png" }) {
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            }
        }
    `,
  );

  return (
    <section className={styles.container}>
      <div className={styles.leftImgContainer}>
        <BackgroundImage
          className={styles.leftImg}
          fluid={downloadAlbums1.childImageSharp.fluid}
        />
      </div>
      <div className={styles.downloadAlbumsWrapper}>
        <div className={styles.downloadAlbums}>
          <img src={appIcon} alt="" className={styles.icon} />
          <h3 className={styles.title}>
            <FormattedMessage id="components.downloadAlbums.title" />
          </h3>
          <p className={styles.note}>
            <FormattedMessage id="components.downloadAlbums.details" />
          </p>
          <div className={styles.appLinksContainer}>
            <div className={styles.installButtonContainer}>
              <InstallButton os="ios">App Store</InstallButton>
            </div>
            <div className={styles.installButtonContainer}>
              <InstallButton os="android">Google Play</InstallButton>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.rightImgContainer}>
        <BackgroundImage
          className={styles.rightImg}
          fluid={downloadAlbums2.childImageSharp.fluid}
        />
      </div>
    </section>
  );
};

export default DownloadAlbums;
