export const isBrowser = (): boolean => typeof window !== 'undefined';

// eslint-disable-next-line import/prefer-default-export
export const detectMobileOs = (): 'ios' | 'android' | 'other' => {
  if (isBrowser() && navigator.userAgent.match(/iPhone|iPad|iPod/i)) return 'ios';
  if (isBrowser() && navigator.userAgent.match(/Android/i)) return 'android';

  return 'other';
};

export const getLang = (): string => (isBrowser() && window.location.pathname.includes('/en/') ? 'en' : 'fr');
