import classNames from 'classnames';
import React from 'react';

import { getLang } from 'helpers/common';

import android from './icons/android.svg';
import ios from './icons/ios.svg';

import styles from './InstallButton.module.scss';

type OS = 'ios' | 'android';

interface App {
  ENLink: string;
  FRLink: string;
  icon: string;
}

type Apps = { [key in OS]: App }

const APPS: Apps = {
  ios: {
    icon: ios,
    ENLink: 'https://albums.app.link/website',
    FRLink: 'https://albums.app.link/Websitefr',
  },
  android: {
    icon: android,
    ENLink: 'https://albums.app.link/website',
    FRLink: 'https://albums.app.link/Websitefr',
  },
};

interface InstallButtonProps {
  os: OS;
}

const InstallButton: React.FC<InstallButtonProps> = ({
  os,
  children,
}) => {
  const link = getLang() === 'en' ? APPS[os].ENLink : APPS[os].FRLink;

  return (
    <a
      href={link}
      rel="noreferrer"
      target="_blank"
      className={classNames(styles.button, styles[os])}
    >
      <img src={APPS[os].icon} alt="" className={styles.osLogo} />
      {children}
    </a>
  );
};

export default InstallButton;
