import React from 'react';

import facebook from './icons/facebook.svg';
import instagram from './icons/instagram.svg';
import linkedin from './icons/linkedin.svg';
import twitter from './icons/twitter.svg';
import youtube from './icons/youtube.svg';

import styles from './SocialLink.module.scss';

type ServiceId = 'instagram' | 'twitter' | 'facebook' | 'youtube' | 'linkedin';

interface Service {
  link: string;
  name: string;
  icon: string;
}

type Services = { [key in ServiceId]: Service };

const SERVICES: Services = {
  instagram: {
    link: 'https://www.instagram.com/albums.app/',
    name: 'Instagram',
    icon: instagram,
  },
  twitter: {
    link: 'https://twitter.com/albumsapp',
    name: 'Twitter',
    icon: twitter,
  },
  facebook: {
    link: 'https://www.facebook.com/albumsapp/',
    name: 'Facebook',
    icon: facebook,
  },
  youtube: {
    link: 'https://www.youtube.com/channel/UCupTZ_kODQQF61TeUkjflzg/',
    name: 'YouTube',
    icon: youtube,
  },
  linkedin: {
    link: 'https://www.linkedin.com/company/albumsapp',
    name: 'LinkedIn',
    icon: linkedin,
  },
};

interface SocialLinkProps {
  service: ServiceId;
}

const SocialLink: React.FC<SocialLinkProps> = ({ service }) => (
  <a href={SERVICES[service].link} className={styles.link} target="_blank" rel="noopener noreferrer">
    <img src={SERVICES[service].icon} alt="" className={styles.icon} />
    {SERVICES[service].name}
  </a>
);

export default SocialLink;
